<template>
  <div
    class="card shadow-card sub-nav-top-bar-v2 w-100"
    @scroll="handleScroll()"
  >
    <div class="row">
      <div :class="[pdf || updateButton ? 'col-lg-9 col-sm-9' : 'col-lg-12']">
        <div class="top-menu-list me-2" :id="mainId" role="tablist">
          <ul class="list-inline mt-4">
            <li
              v-for="(tab, index) in listArray"
              :key="index"
              class="list-inline-item ms-2"
              @click="
                scrollToElementWithMargin(tab.id, scrollToElementMarginMain)
              "
            >
              <a
                :data-section-main="'#' + tab.id"
                :id="tab.id + '_tab'"
                :class="['#' + tab.id === activeTab ? 'active' : '']"
              >
                <img :src="tab.icon" :alt="tab.alt" />
                <span class="social-icon text-xs-center ms-1">
                  {{ tab.name }}
                </span>
              </a>
            </li>
          </ul>
          <hr v-if="haveSubList" />
        </div>
      </div>
      <div class="col-lg-3 col-sm-3 mt-4" style="text-align: right" v-if="pdf">
        <div
          class="spinner-grow text-primary me-4"
          role="status"
          v-if="loading"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <img
          class="me-2 cursor-pointer"
          v-if="!loading"
          src="/media/pdf-icon.png"
          alt="pdf-icon"
          @click="downloadPdf"
          style="width: 40px"
        />
      </div>
      <div
        class="col-lg-3 col-sm-3 mt-4"
        style="text-align: right"
        v-if="updateButton"
      >
        <div
          class="spinner-grow text-primary me-4"
          role="status"
          v-if="loading"
        >
          <span class="sr-only">Updating...</span>
        </div>
        <el-tooltip
          class="box-item"
          effect="dark"
          content=" Click to update"
          placement="left-start"
        >
          <el-icon
            class="me-6 cursor-pointer"
            style="font-size: 18px"
            v-if="!loading"
            @click="updateData"
          >
            <Refresh class="refresh-icon theme-color" />
          </el-icon>
        </el-tooltip>
      </div>
    </div>
    <div class="d-flex justify-content-between" v-if="haveSubList">
      <div class="d-flex justify-content-start">
        <div class="top-menu-list" :id="subId">
          <ul class="list-inline">
            <li
              class="list-inline-item ms-4 sub-list-inline"
              v-for="(list, index) in subListArray"
              :key="index"
            >
              <span
                :class="[
                  'list-text text-xs-center',
                  activeSubList === list.id ? 'active-sub-text' : '',
                ]"
                @click="scrollToElementWithMargin(list.id, 125)"
                :data-section="'#' + list.id"
              >
                {{ list.tabName }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onBeforeMount, ref, watch } from "vue";
import { scrollToElementWithMargin } from "@/utils/staticHelper";
import $ from "jquery";
import { Refresh } from "@element-plus/icons-vue";

export default {
  name: "SubTopNavBarV2",
  components: { Refresh },
  props: {
    mainId: {
      type: String,
      required: true,
    },
    subId: {
      type: String,
      required: true,
    },
    currentActiveTab: {
      type: String,
      required: true,
    },
    currentActiveSubList: {
      type: String,
      default: "",
    },
    haveSubList: {
      type: Boolean,
      default: true,
    },
    tabsList: {
      type: Array,
      required: true,
    },
    subList: {
      type: Array,
      default: () => [],
    },
    scrollToElementMarginMain: {
      type: Number,
      default: 80,
    },
    pdf: {
      type: Boolean,
      default: false,
    },
    updateButton: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const listArray = ref(props.tabsList);
    const activeTab = ref("");
    const activeSubList = ref(props.currentActiveSubList);
    const subListArray = ref(props.subList);
    onBeforeMount(() => {
      window.addEventListener("scroll", handleScroll);
    });
    const handleScroll = () => {
      const scrollPos = $(document).scrollTop() + 250;
      $("#" + props.mainId + " a").each(function () {
        const currLink = $(this);
        const refElement = $(currLink.data("section-main"));
        if (
          refElement.position().top <= scrollPos &&
          refElement.position().top + refElement.height() > scrollPos
        ) {
          $("#" + props.mainId + "a").removeClass("active");
          changeTab(currLink.data("section-main"));
          currLink.addClass("active");
        } else {
          currLink.removeClass("active");
        }
      });
      if (scrollPos === 250 || scrollPos < 250) {
        $(activeTab.value + "_tab").addClass("active");
        changeTab(activeTab.value);
      }
      $("#" + props.subId + " li span").each(function () {
        const currList = $(this);
        const refList = $(currList.attr("data-section"));
        if (
          refList.position().top <= scrollPos &&
          refList.position().top + refList.height() > scrollPos
        ) {
          activeSubList.value = currList.attr("data-section").replace("#", "");
        }
      });
    };
    const changeTab = (tabName) => {
      activeTab.value = tabName;
      if (props.haveSubList) {
        context.emit("changeSubList", tabName);
      }
    };

    const downloadPdf = () => {
      if (!props.loading) {
        context.emit("generateReport");
      }
    };

    const updateData = () => {
      if (!props.loading) {
        context.emit("updateDetail");
      }
    };
    watch(
      () => props.subList,
      (values) => {
        if (props.haveSubList) {
          subListArray.value = values;
        }
      },
      { immediate: true, deep: true }
    );
    watch(
      () => props.currentActiveTab,
      (value) => {
        activeTab.value = value;
      },
      { immediate: true, deep: true }
    );
    return {
      listArray,
      activeTab,
      activeSubList,
      subListArray,
      handleScroll,
      scrollToElementWithMargin,
      downloadPdf,
      updateData,
    };
  },
};
</script>
<style lang="scss" scoped>
.active-sub-text {
  color: black !important;
  font-weight: 600 !important;
}

.navbar--hidden {
  box-shadow: none;
  transform: translate3d(0, -100%, 0);
}

.sub-list-inline {
  padding: 2px !important;
}

.list-text {
  font-size: 12px;
  color: #33647f;
  font-weight: 400;
}

hr {
  opacity: 1.25;
  color: #dee4ee;
}
.refresh-icon {
  height: 1.75em;
  width: 1.5em;
}
</style>
