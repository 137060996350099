<script>
import { ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";
import moment from "moment";

export default {
  name: "LineAndAreaChart",
  props: {
    chartHeight: {
      type: Number,
      default: 400,
    },
    chartType: {
      type: String,
      default: "area",
    },
    formatNumber: {
      type: Boolean,
      default: true,
    },
    showYAxisTitle: {
      type: Boolean,
      default: true,
    },
    xAxisDate: {
      type: Boolean,
      default: true,
    },
    tooltipXFormat: {
      type: String,
      default: "MMM YY",
    },
    dateFormat: {
      type: String,
      default: "MMM YY",
    },
    fraction: {
      type: Number,
      default: 1,
    },
    chartSeries: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    chartStroke: {
      type: Object,
      default: () => {
        return {
          curve: "smooth",
          show: true,
          width: [3, 1],
          dashArray: [0, 8],
        };
      },
    },
    chartFill: {
      type: Object,
      default: () => {
        return {
          type: "gradient",
          gradient: {
            shadeIntensity: 0,
            inverseColors: true,
          },
          opacity: 1,
        };
      },
    },
    zoom: {
      type: Boolean,
      default: false,
    },
    annotations: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props) {
    const fontSize = ref("12px");
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const options = ref({
      annotations: props.annotations,
      chart: {
        height: props.chartHeight,
        fontFamily: "inherit",
        type: props.chartType,
        zoom: {
          enabled: props.zoom,
          type: "xy",
        },
        toolbar: {
          show: props.zoom,
        },
      },
      plotOptions: {},
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        fontSize: fontSize.value,
        fontWeight: 400,
        labels: {
          colors: labelColor,
          useSeriesColors: false,
        },
      },
      fill: props.chartFill,
      stroke: props.chartStroke,
      colors: ["#009ef7", "rgb(0, 179, 0)"],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        strokeWidth: 3,
      },
      tooltip: {
        style: {
          fontSize: fontSize.value,
        },
        x: {
          formatter: function (value) {
            if (props.xAxisDate) {
              return moment(props.categories[value - 1]).format(
                props.tooltipXFormat
              );
            } else {
              return formatNumberIntoHumanizeForm(props.categories[value - 1]);
            }
          },
        },
        y: {
          formatter: function (value) {
            return formatNumberIntoHumanizeForm(value, props.fraction);
          },
        },
      },
      yaxis: {
        title: {
          text: props.showYAxisTitle
            ? props.chartSeries.length > 0
              ? props.chartSeries[0].name
              : ""
            : "",
          style: {
            color: labelColor,
            fontSize: fontSize.value,
            fontWeight: 400,
          },
        },
        labels: {
          formatter: (value) => {
            return formatNumberIntoHumanizeForm(value);
          },
          style: {
            colors: labelColor,
            fontSize: fontSize.value,
            fontWeight: 400,
          },
        },
      },
      labels: props.categories,
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickAmount: 24,
        forceNiceScale: true,
        labels: {
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          style: {
            colors: labelColor,
            fontSize: fontSize.value,
          },
          formatter: (value) => {
            if (props.xAxisDate) {
              return moment(value).format(props.dateFormat);
            } else {
              return formatNumberIntoHumanizeForm(value);
            }
          },
        },
        crosshairs: {
          position: "front",
          stroke: {
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
    });
    const series = ref(props.chartSeries);
    return { options, series };
  },
};
</script>
<template>
  <apexchart
    :height="chartHeight"
    :type="chartType"
    :options="options"
    :series="series"
  ></apexchart>
</template>
